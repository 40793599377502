.verify__email {
  width: 45%;
  margin: 0 auto;
}
.verifyForm {
  padding: 20px;
  border-radius: 10px;
  background-color: white;
}
.verify__boxes {
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
}
.verify__boxes input {
  width: 50%;
  border: 1px solid #979797;
  border-radius: 5px;
  display: block;
  text-align: center;
  font-size: 1.7rem;
  font-weight: 600;
  padding: 10px 0;
  margin: 0 auto;
}
.verify__boxes input:focus-visible {
  outline: 1px solid #00afef;
}
.verify__boxes span {
  display: inline-block;
  color: #ea6a3e;
}
.verifyHeader {
  text-align: center;
  background-color: #00afef;
  color: #ffffff;
  margin-bottom: 20px;
  padding: 10px 0;
  border-radius: 5px;
}
.verifyHeader h2 {
  font-size: 1.5rem;
  margin: 5px 0;
  font-weight: 600;
}
.verifyHeader p {
  font-size: 1.1rem;
}
.verifyHeader__icons {
  margin: 10px auto;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.verifyHeader__icon {
  font-size: 2.2rem;
  box-shadow: 2px 2px 10px rgba(207, 206, 206, 0.5);
}
.verify__email-btn {
  text-align: center;
  margin-top: 30px;
}
.verify__email-btn button {
  padding: 10px 30px;
  border: none;
  background-color: #00afef;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 5px;
}
@media (max-width: 992px) {
  .verify__email {
    width: 60%;
  }
}
@media (max-width: 768px) {
  .verify__email {
    width: 85%;
  }
}
@media (max-width: 500px) {
  .verify__email {
    width: 95%;
  }
}
