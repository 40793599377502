/* Footer */
/* Reg Footer */
.reg__footer {
  width: 100%;
  background-color: #ffffff;
  color: #222222;
  padding: 20px;
  text-align: center;
  bottom: 0;
}
