@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");
@font-face {
  font-family: FredokaOne;
  src: url("./assets/fonts/FredokaOne-Regular.ttf");
}
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  /* font-family: "Roboto", sans-serif; */
  /* font-family: "Poppins", sans-serif; */
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}
.container {
  width: 85%;
  margin: 0 auto;
  max-width: 1500px;
}
.btns-primary {
  display: inline-block;
  padding: 12px 30px;
  font-size: 0.9rem;
  background-color: #00afef;
  color: #ffffff;
  border-radius: 5px;
  text-decoration: none;
}
.space-bottom {
  padding-bottom: 100px;
}
.spaceTop {
  padding-top: 91px;
}
/* @media */
@media (max-width: 1300px) {
  .container {
    width: 85%;
  }
}
@media (max-width: 1024px) {
  .container {
    width: 95%;
  }
}
@media (max-width: 768px) {
  .container {
    width: 98%;
  }
  .spaceTop {
    padding-top: 71px;
  }
}
