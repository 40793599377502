.pwd__reset-header {
  margin-bottom: 15px;
}
.pwd__reset-header p {
  font-size: 0.9rem;
  line-height: 1.5;
  color: #222222;
}
.reset__PWD-bg {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  width: 45%;
  margin: 0 auto;
}
.reset_veri_code {
  padding: 20px;
  color: #ffffff;
  background-color: #0096cd;
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
  border-radius: 5px;
}
@media (max-width: 1300px) {
  .reset__PWD-bg {
    width: 75%;
  }
}
@media (max-width: 768px) {
  .reset__PWD-bg {
    width: 60%;
  }
}
@media (max-width: 650px) {
  .reset__PWD-bg {
    width: 95%;
  }
}
